import { useCallback, useEffect, useRef, useState } from 'react';

import { ArrowButton } from 'Components/desktop/carousel';

import { LeftArrowSvg, RightArrowSvg } from 'Assets/svg/productPage';

import useRtl from 'Hooks/useRtl';
import { debounce } from 'Utils/gen';
import PlatformUtils from 'Utils/platformUtils';

import {
	DATA_INDEX_ATTRIBUTE,
	DEFAULT_BLUR_WIDTH_IN_PX,
	LOAD_BUFFER_SIZE,
} from './constants';
import { SwiperDirection, SwiperIndexRange, SwiperProps } from './interface';
import {
	Container,
	PaginationDot,
	PaginationDotsWrapper,
	Slide,
	SlidesWrapper,
} from './styles';

const IS_MOBILE = !PlatformUtils.isDesktop();
const startEvent = IS_MOBILE ? 'touchstart' : 'mouseenter';
const endEvent = IS_MOBILE ? 'touchend' : 'mouseleave';

const getSlideIndex = (slideElement: Element): number => {
	return +(slideElement.getAttribute(DATA_INDEX_ATTRIBUTE) || 0);
};
const getInitialRenderIndexRange = (
	batching: boolean,
	totalCount: number,
	renderBatchSize: number,
	loop: boolean,
): SwiperIndexRange => {
	if (!batching) {
		return { lower: 0, upper: 0 };
	}

	if (totalCount <= 5) {
		return {
			lower: totalCount,
			upper: totalCount - 1,
		};
	}

	if (!loop) {
		return {
			lower: renderBatchSize - 1, // sequential load from left to right
			upper: totalCount - 1,
		};
	}

	const leftSideCount = Math.max(1, Math.floor(renderBatchSize / 3));

	return {
		lower: renderBatchSize - leftSideCount - 1,
		upper: totalCount - leftSideCount,
	};
};
const getNextRenderIndexRange = (
	renderIndexRange: SwiperIndexRange,
	currIndex: number,
	direction: SwiperDirection,
	renderBatchSize: number,
	loop: boolean,
): SwiperIndexRange => {
	const { lower, upper } = renderIndexRange;
	if (lower < upper) {
		if (direction === 'forward') {
			const shouldAddNextBatch =
				Math.abs(lower - currIndex) <= LOAD_BUFFER_SIZE;
			return {
				lower: shouldAddNextBatch ? lower + renderBatchSize : lower,
				upper,
			};
		}

		const shouldAddNextBatch =
			Math.abs(upper - currIndex) <= LOAD_BUFFER_SIZE;
		return {
			lower,
			upper:
				loop && shouldAddNextBatch
					? Math.max(0, upper - renderBatchSize)
					: upper,
		};
	}

	return renderIndexRange;
};
const getDirection = (oldIndex: number, newIndex: number): SwiperDirection => {
	return (newIndex === oldIndex && newIndex === 0) ||
		(Math.abs(newIndex - oldIndex) <= 1 && newIndex > oldIndex)
		? 'forward'
		: 'backward';
};
const wheelListener = (e: MouseEvent) => {
	e.preventDefault();
};

export const Swiper = ({
	slideWidth,
	slidesToShow = 1,
	slidesToScrollBy = Math.max(1, slidesToShow - 1),
	transition = 'slide-in',
	rtl,
	loop = false,
	batching = false,
	renderBatchSize = 5,
	pauseAutoPlayOnHover = true,
	autoPlay = false,
	autoPlayTimeMs = 3000,
	onSlideChanged,
	nextPrevControls = 'hide',
	nextPrevControlSize = 'medium',
	showBlurNearEdges = false,
	blurWidthInPx = DEFAULT_BLUR_WIDTH_IN_PX,
	paginationDots = false,
	paginationDotsPosX,
	paginationDotsPosY,
	nextPrevControlPosX = '1.6em',
	nextPrevControlPosY = '50%',
	swiperRef,
	children = [],
	beforeChangeCallback,
	allowLoopOnMobile = false,
	hasRoundedCorners = false,
	onChevronClick,
}: SwiperProps) => {
	const slidesWrapperRef = useRef<HTMLDivElement>(null);
	const slideRefs = useRef<HTMLDivElement[]>([]);
	const observer = useRef<IntersectionObserver | null>(null);
	const [currentPaginationIndex, setCurrentPaginationIndex] = useState(0);
	const [currIndex, setCurrIndex] = useState(0);
	const autoPlayTimerId = useRef<number | undefined>();
	const [renderExclusionRange, setRenderExclusionRange] =
		useState<SwiperIndexRange>(
			getInitialRenderIndexRange(
				batching,
				children.length,
				renderBatchSize,
				loop,
			),
		);
	const [direction, setDirection] = useState<SwiperDirection>('forward');
	const rtlEnabled = useRtl(rtl);
	const [showNextPrevControls, setShowNextPrevControls] = useState(
		nextPrevControls === 'show',
	);
	const isSlideInTransition = transition === 'slide-in' || slidesToShow > 1;
	const isFadeInTransition = transition === 'fade-in' && slidesToShow === 1;
	const nextPrevControlDimension =
		nextPrevControlSize === 'medium' ? '2.25rem' : '1.5rem';
	const [isCustomScrollingInProgress, setIsCustomScrollingInProgress] =
		useState(false);
	const [wasLastScrollCustom, setWasLastScrollCustom] = useState<
		boolean | null
	>(null); // null means no scroll has happened yet

	const isLoop = () => {
		const slidesWrapperEl = slidesWrapperRef?.current;
		if (!slidesWrapperEl) return false;

		const firstSlide = slideRefs.current[0];

		return (
			(!IS_MOBILE || allowLoopOnMobile) &&
			loop &&
			children.length > 2 &&
			slidesWrapperEl.offsetWidth === firstSlide.offsetWidth
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const navigate = (direction: SwiperDirection) => {
		if (isFadeInTransition) {
			setCurrIndex(prevIndex => {
				let nextIndex =
					(prevIndex + (direction === 'forward' ? 1 : -1)) %
					children.length;
				if (nextIndex < 0) {
					nextIndex = children.length - 1;
				}
				return nextIndex;
			});
			return;
		}

		setDirection(direction);
		setIsCustomScrollingInProgress(true);
		slidesWrapperRef?.current?.parentElement?.addEventListener(
			'wheel',
			wheelListener,
			{ passive: false },
		);
	};

	useEffect(() => {
		setShowNextPrevControls(nextPrevControls === 'show');
	}, [nextPrevControls]);

	useEffect(() => {
		const slidesWrapperEl = slidesWrapperRef?.current;
		if (!slidesWrapperEl || !isCustomScrollingInProgress) {
			slidesWrapperEl?.parentElement?.removeEventListener(
				'wheel',
				wheelListener,
			);
			return;
		}

		let scrollTo = 0;
		const elements = slideRefs.current;
		const wrapperWidth = slidesWrapperEl.offsetWidth;

		if (slideWidth === 'variable') {
			let wrapperOffset = slidesWrapperEl.scrollLeft;
			const actualBlurWidth = showBlurNearEdges ? blurWidthInPx : 0;

			wrapperOffset +=
				direction === 'forward'
					? wrapperWidth - actualBlurWidth
					: actualBlurWidth;

			const elementAtTheEdgeIndex = elements.findIndex(element => {
				return element.offsetLeft + element.offsetWidth > wrapperOffset;
			});

			const elementAtTheEdge = elements[elementAtTheEdgeIndex];
			if (elementAtTheEdge) {
				scrollTo = elementAtTheEdge.offsetLeft - actualBlurWidth;

				if (direction === 'backward') {
					const rightOfElementAtTheEdge =
						elementAtTheEdge.offsetLeft +
						elementAtTheEdge.offsetWidth +
						actualBlurWidth;

					scrollTo = rightOfElementAtTheEdge - wrapperWidth;
				}
			} /* else {
				console.log('Should not happen as slides are touching each other');
			}*/
		} else {
			const firstSlide = elements[0];
			const fixedSlideWidth = firstSlide.offsetWidth;
			const scrollBy = fixedSlideWidth * slidesToScrollBy;
			const maxScrollLeft = slidesWrapperEl.scrollWidth - wrapperWidth;

			if (direction === 'forward') {
				scrollTo = Math.abs(slidesWrapperEl.scrollLeft) + scrollBy;
				scrollTo = Math.min(scrollTo, maxScrollLeft);
			} else if (direction === 'backward') {
				scrollTo = Math.abs(slidesWrapperEl.scrollLeft) - scrollBy;
				if (!loop && scrollTo < 0) {
					scrollTo = 0;
				}
			}

			// right edge. Specially handle to ensure a non-edge slide is starts at the left and it does
			// no affected when we put the snap scrolling back
			if (
				direction === 'backward' &&
				slidesWrapperEl.scrollLeft === maxScrollLeft
			) {
				const fractionOfPartialSlideVisible =
					wrapperWidth % fixedSlideWidth;
				if (fractionOfPartialSlideVisible > 0) {
					scrollTo =
						slidesWrapperEl.scrollLeft -
						(fixedSlideWidth - fractionOfPartialSlideVisible);
				}
			}
		}

		let scrollTimeout: NodeJS.Timeout;
		slidesWrapperEl.addEventListener(
			'scroll',
			function onScroll() {
				clearTimeout(scrollTimeout);
				scrollTimeout = setTimeout(() => {
					setIsCustomScrollingInProgress(false);
					slidesWrapperEl.removeEventListener('scroll', onScroll);
				}, 50);
			},
			{
				passive: true,
			},
		);

		requestAnimationFrame(() => {
			slidesWrapperEl.scrollTo({
				left: scrollTo,
				behavior: 'smooth',
			});
		});
	}, [isCustomScrollingInProgress]);

	const startAutoPlay = useCallback(() => {
		if (!autoPlay) return;

		stopAutoPlay();
		autoPlayTimerId.current = window.setInterval(
			() => navigate('forward'),
			autoPlayTimeMs,
		);
	}, [autoPlay, autoPlayTimeMs, navigate]);

	const stopAutoPlay = () => {
		window.clearInterval(autoPlayTimerId.current);
		autoPlayTimerId.current = undefined;
	};

	const moveForward = () => {
		navigate('forward');
	};

	const moveBackward = () => {
		navigate('backward');
	};

	const updateShowNextPrevControls = (show: boolean) => {
		if (nextPrevControls === 'show-on-hover') {
			setShowNextPrevControls(show);
		}
	};

	const onMouseEnter = () => {
		if (autoPlay && pauseAutoPlayOnHover) {
			stopAutoPlay();
		}
		updateShowNextPrevControls(true);
	};

	const onMouseLeave = () => {
		if (autoPlay && pauseAutoPlayOnHover) {
			startAutoPlay();
		}
		updateShowNextPrevControls(false);
	};

	const onArrowClicked: (isLeft: boolean) => void = debounce(
		(isLeft: boolean) => {
			if (isCustomScrollingInProgress) {
				return; // TODO show disabled state as well?
			}

			const shouldStopStartAutoPlay = autoPlay && !pauseAutoPlayOnHover;
			if (shouldStopStartAutoPlay) {
				stopAutoPlay();
			}
			if (rtlEnabled) {
				(isLeft ? moveForward : moveBackward)();
				onChevronClick?.(isLeft ? 'forward' : 'backward');
			} else {
				(isLeft ? moveBackward : moveForward)();
				onChevronClick?.(isLeft ? 'backward' : 'forward');
			}
			if (shouldStopStartAutoPlay) {
				startAutoPlay();
			}
		},
		100,
	);

	useEffect(() => {
		if (swiperRef) {
			swiperRef.current = {
				...swiperRef?.current,
				nextSlide: moveForward,
				prevSlide: moveBackward,
				scrollToSlide(index) {
					const slidesWrapperEl = slidesWrapperRef?.current;
					if (slidesWrapperEl) {
						const slide =
							slidesWrapperEl.querySelector<HTMLDivElement>(
								`[${DATA_INDEX_ATTRIBUTE}="${index}"]`,
							);
						if (slide) {
							slidesWrapperEl.scrollLeft = slide.offsetLeft;
						}
					}
				},
			};
		}

		const slidesWrapperEl = slidesWrapperRef?.current;

		if (slidesWrapperEl) {
			slidesWrapperEl.parentElement?.addEventListener(
				startEvent,
				onMouseEnter,
				{ passive: true },
			);
			slidesWrapperEl.parentElement?.addEventListener(
				endEvent,
				onMouseLeave,
				{ passive: true },
			);
		}

		return () => {
			if (swiperRef) {
				swiperRef.current = null;
			}
			if (slidesWrapperEl) {
				slidesWrapperEl.parentElement?.removeEventListener(
					startEvent,
					onMouseEnter,
				);
				slidesWrapperEl.parentElement?.removeEventListener(
					endEvent,
					onMouseLeave,
				);
			}
			observer.current?.disconnect();
			stopAutoPlay();
		};
	}, []);

	useEffect(() => {
		if (!isSlideInTransition) {
			return;
		}

		observer.current?.disconnect(); // when children change dynamically. May need to support activeIndex as props

		const handler = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(entry => {
				if (entry.intersectionRatio === 1) {
					const slideIndex = getSlideIndex(entry.target);
					if (!isNaN(slideIndex)) {
						setCurrIndex(oldCurrIndex => {
							setDirection(
								getDirection(oldCurrIndex, slideIndex),
							);
							return slideIndex;
						});
					}
				} else if (entry.intersectionRatio >= 0.52) {
					const slideIndex = getSlideIndex(entry.target);
					if (!isNaN(slideIndex)) {
						beforeChangeCallback?.(slideIndex);
						setCurrentPaginationIndex(slideIndex);
						setWasLastScrollCustom(isCustomScrollingInProgress);

						/* If isCustomScrollingInProgress is false, and still observer runs means 
                        it is coming because of a swipe. If it is coming from a swipe, call stopAutoplay fn 
                        if autoplay is true, and start it again. This ensures that the interval restarts. */
						if (!isCustomScrollingInProgress) {
							startAutoPlay(); // Didn't call stopAutoPlay as this fn intercally calls stopAutoPlay
						}
					}
				}
			});
		};

		const options = {
			root: slidesWrapperRef?.current,
			rootMargin: '0px',
			threshold: [1, 0.52],
		};

		observer.current = new IntersectionObserver(handler, options);
		for (const node of slideRefs.current) {
			if (node) {
				observer.current.observe(node);
			}
		}
	}, [children.length, isCustomScrollingInProgress]);

	useEffect(() => {
		if (onSlideChanged) {
			onSlideChanged({
				index: currIndex,
				isLeftArrowEnabled: shouldShowNextPrevBtn(true, true),
				isRightArrowEnabled: shouldShowNextPrevBtn(false, true),
				isScrollCustom: wasLastScrollCustom,
			});
		}

		if (currIndex && batching) {
			setRenderExclusionRange(
				getNextRenderIndexRange(
					renderExclusionRange,
					currIndex,
					direction,
					renderBatchSize,
					loop,
				),
			);
		}

		const slidesWrapperEl = slidesWrapperRef?.current;
		if (!slidesWrapperEl || isFadeInTransition || !isLoop()) return;

		let {
			scrollWidth,
			scrollLeft,
			offsetWidth: width,
			children: childrenNodes,
		} = slidesWrapperEl;

		const dir = rtlEnabled ? -1 : 1;
		scrollLeft = Math.abs(scrollLeft);

		if (scrollLeft <= width) {
			slidesWrapperEl.prepend(childrenNodes[childrenNodes.length - 1]);
			slidesWrapperEl.scrollLeft = (scrollLeft + width) * dir;
		} else if (Math.floor(scrollWidth - scrollLeft) <= width) {
			slidesWrapperEl.append(childrenNodes[0]);
			slidesWrapperEl.scrollLeft = (scrollLeft - width) * dir;
		}
	}, [currIndex, children.length]);

	useEffect(() => {
		if (autoPlay) {
			startAutoPlay();
		} else {
			stopAutoPlay();
		}
	}, [autoPlay, autoPlayTimeMs, startAutoPlay]);

	const shouldShowNextPrevBtn = (
		isLeft: boolean,
		isExternalArrowButtons = false,
	): boolean => {
		const slidesWrapperEl = slidesWrapperRef?.current;
		if (
			!slidesWrapperEl ||
			IS_MOBILE ||
			(!isExternalArrowButtons && !showNextPrevControls) ||
			children.length === 1
		)
			return false;

		if (isFadeInTransition || isLoop()) {
			return true;
		}

		return isLeft
			? slidesWrapperEl.scrollLeft > 0
			: Math.floor(
					slideRefs.current[
						slideRefs.current.length - 1
					]?.getBoundingClientRect()?.right,
			  ) -
					Math.floor(
						slidesWrapperEl?.getBoundingClientRect()?.right,
					) >
					0;
	};

	const getNextPrevPos = (posType: 'X' | 'Y'): string | undefined => {
		let pos;
		if (posType === 'X') {
			pos = nextPrevControlPosX;
			if (pos === 'edge') {
				pos = '-1.6em';
			}
		} else {
			pos = nextPrevControlPosY;
		}

		return pos;
	};

	const onSlideClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const slideEle = event.currentTarget;
		if (slideWidth !== 'variable' || !slideEle) return;

		const slidesWrapperEl = slidesWrapperRef?.current;
		if (!slidesWrapperEl || !slideEle) return;

		/**
		 Usecases to scroll to center
		 - intersecting at left edge
		 - intersecting at right edge and we also want to center a slide that is fully
			visible when next slide is not so that user knows there is more content
		 */
		const actualBlurWidth = showBlurNearEdges ? blurWidthInPx : 0;
		if (
			IS_MOBILE ||
			slideEle.offsetLeft <
				slidesWrapperEl.scrollLeft + actualBlurWidth ||
			slideEle.offsetLeft + slideEle.offsetWidth >
				slidesWrapperEl.scrollLeft +
					slidesWrapperEl.offsetWidth -
					actualBlurWidth
		) {
			slideEle.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center',
			});
		}
	};

	if (!children.length) {
		return null;
	}

	const showPrevBtn = shouldShowNextPrevBtn(true);
	const showNextBtn = shouldShowNextPrevBtn(false);

	return (
		<Container>
			<SlidesWrapper
				rtl={rtlEnabled}
				ref={slidesWrapperRef as any}
				$enableSnapScrolling={
					!isCustomScrollingInProgress && slideWidth !== 'variable'
				}
				$hasRoundedCorners={hasRoundedCorners}
				slideWidth={slideWidth}
				$showPrevBtn={showPrevBtn}
				$showNextBtn={showNextBtn}
				$showBlurNearEdges={showBlurNearEdges}
				blurWidthInPx={blurWidthInPx}
			>
				{children.map((child, i) => (
					<Slide
						key={i}
						ref={
							((node: HTMLDivElement) =>
								(slideRefs.current[i] = node)) as any
						}
						{...{ [DATA_INDEX_ATTRIBUTE]: i }}
						slideWidth={slideWidth}
						slidesToShow={slidesToShow}
						active={i === currIndex}
						transition={transition}
						$enableSnapScrolling={!isCustomScrollingInProgress}
						onClick={onSlideClick}
					>
						{(!batching ||
							i <= renderExclusionRange.lower ||
							i >= renderExclusionRange.upper) &&
							child}
					</Slide>
				))}
			</SlidesWrapper>
			{paginationDots && children.length > 1 && (
				<PaginationDotsWrapper
					rtl={rtlEnabled}
					paginationDotsPosX={paginationDotsPosX}
					paginationDotsPosY={paginationDotsPosY}
				>
					{[...Array(children.length)].map((_, i) => (
						<PaginationDot
							key={i}
							isActive={i === currentPaginationIndex}
							data-num={i}
						/>
					))}
				</PaginationDotsWrapper>
			)}
			{showPrevBtn ? (
				<ArrowButton
					onClick={event => {
						event.stopPropagation();
						onArrowClicked(true);
					}}
					left
					size={nextPrevControlDimension}
					posX={getNextPrevPos('X')}
					posY={getNextPrevPos('Y')}
					aria-label='Previous slide'
				>
					<LeftArrowSvg className='prev-button' />
				</ArrowButton>
			) : null}
			{showNextBtn ? (
				<ArrowButton
					onClick={event => {
						event.stopPropagation();
						onArrowClicked(false);
					}}
					right
					size={nextPrevControlDimension}
					posX={getNextPrevPos('X')}
					posY={getNextPrevPos('Y')}
					aria-label='Next slide'
				>
					<RightArrowSvg className='next-button' />
				</ArrowButton>
			) : null}
		</Container>
	);
};

export default Swiper;
